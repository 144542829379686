@import "../../../utilities/styleConstants.scss";

.homepage-outer-box {
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $header-height-desktop;

    @media (max-width: 750px) {
        padding: $header-height-desktop 0 112px 0;
    }

    @media (max-width: 500px) {
        padding-top: $header-height-mobile;
    }

    .welcome-text {
        background-color: rgba(48, 32, 24, 0.8);
        margin-top: 0;
        padding: 16px;
        font-size: 24px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        @media (max-width: 960px) {
            padding: 8px;
            font-size: 16px;
        }
    }

    .slogan {
        margin: 15px;
        font-size: 36px;
    }

    .trainings-container {
        width: 75%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}
