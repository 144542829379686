.contacts-img-container {
  background-color: rgb(60, 15, 20);
  color: rgb(80, 15, 20);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
  transition: font-size 0.3s ease-in-out, min-width 0.2s ease-in-out,
    transform 0.2s ease-in-out, background-color 0.5s ease-in-out;
  margin: 0 50px;
  font-weight: bold;

  .widget-text {
    display: none;
    text-align: center;
  }

  .widget-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.expandable-widget {
    cursor: pointer;
    &:hover {
      min-width: 11rem;
      font-size: 18px;
      padding: 5px 15px;
      background-color: rgb(233, 229, 229);
      display: flex;
      width: auto;
      justify-content: center;
      align-items: center;

      .widget-text {
        display: block;
      }

      .widget-image {
        visibility: none;
        display: none;
      }
    }
  }
}

.hoverscale {
  &:hover {
    transform: scale(1.1);
    background-color: rgb(90, 15, 20);
  }
}
