@import "../../utilities/styleConstants.scss";

.main-menu-outer-box {
    .main-menu-backdrop {
        z-index: -1;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: $header-height-desktop;
        left: 0;
        display: none;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 0.2s ease-in-out;
        &.open-state {
            display: block;
            z-index: 3000;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.66);
            @media (max-width: 960px) {
                background-color: rgba(0, 0, 0, 1);
            }
        }
        @media (max-width: 500px) {
            top: 60px;
        }
    }
    .main-menu-items-container {
        z-index: 3100;
        position: absolute;
        top: $header-height-desktop;
        left: 0;
        transform: translate(-100%);
        height: calc(100vh - $header-height-desktop);
        width: 100%;
        background-color: rgba(26, 9, 12, 0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 0;
        transition: all 0.2s ease-in-out;
        overflow-y: auto;

        @media (min-width: 960px) {
            padding: 12px 4px;
            width: 360px;
            left: 0;
        }

        @media (max-width: 500px) {
            top: 60px;
        }

        &.open-state {
            transform: translate(0);
        }

        .main-menu-item {
            color: white;
            width: 70%;
            max-width: 400px;
            text-align: center;
            text-decoration: none;
            padding: 8px 0;
            flex-grow: 1;
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 2px auto;
            cursor: pointer;
            border-radius: 8px;
            position: relative;
            font-weight: bold;
            transition: all 0.2s ease-in-out;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            background-color: rgba(56, 23, 23, 0.315);

            &.active {
                border-left: 4px solid rgb(134, 47, 47);
                border-right: 4px solid rgb(134, 47, 47);
            }

            @media (min-width: 700px) {
                &:hover {
                    background-color: rgba(241, 115, 65, 0.767);
                    width: 74%;
                }
            }

            &:last-of-type {
                margin-bottom: 25px;
            }

            @media (max-width: 500px) {
                padding: 9px 0;
                width: 90%;
                &:last-of-type {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
