.content-section-outer-box {
    max-width: 70%;
    height: fit-content;
    padding: 32px 16px;
    background-color: rgb(248, 230, 230);
    color: rgb(51, 30, 30);
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    @media (max-width: 960px) {
        padding: 28px 16px;
    }

    @media (max-width: 750px) {
        padding: 24px 12px;
        max-width: 90%;
    }

    @media (max-width: 500px) {
        font-size: 14px;
        max-width: 100%;
    }
    .content-box {
        display: flex;
        flex-direction: column;
        .img-box {
            background-color: white;
            width: fit-content;
            padding: 4px;
            border-radius: 12px;
            margin-right: 32px;
        }
    }
}
