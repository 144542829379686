@import "../../utilities/styleConstants.scss";
.page-content-box {
    margin-top: $header-height-desktop;
    width: calc(100% - 40px);
    height: 100%;
    min-height: calc(100vh - 70px);
    padding: 20px 20px 112px 20px;
    background-color: rgb(18, 10, 10);
    overflow: auto;
    overflow-x: hidden;
    min-height: unset;

    @media (max-width: 500px) {
        margin-top: $header-height-mobile;
    }
}
