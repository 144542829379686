.subpage-selection-outer-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 36px;

    @media (max-width: 960px) {
        margin: 36px 0;
    }

    @media (max-width: 750px) {
        margin: 36px 0;
    }

    @media (max-width: 500px) {
        margin: 36px 0;
    }

    .subpage-option {
        cursor: pointer;
        width: 120px;
        padding: 6px 0;
        margin: 8px;
        outline: none;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        background-color: rgb(248, 230, 230);
        color: rgb(51, 30, 30);
        box-sizing: border-box;
        transition: all 0.25s ease-in-out;

        &:hover {
            transform: scale(1.08);
        }

        &.selected {
            background-color: rgb(78, 39, 39);
            color: white;
        }
    }
}
