.fudoshin-footer {
    clear: both;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(10, 3, 3);
    background: linear-gradient(109deg, rgba(18, 5, 5, 1) 0%, rgba(10, 3, 3, 1) 93%);
    width: 100%;

    .slogan {
        font-size: 17px;
        margin-bottom: 18px;
        height: 25px;
        color: rgb(127, 67, 67);
    }

    @media (max-width: 550px) {
        display: none;
    }
}

.footer-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 0 16px 0;
    color: white;
}

.widget-box {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
