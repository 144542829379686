.card-outer-box {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: rgba(59, 35, 35, 0.85);
    min-width: 150px;
    max-width: 150px;
    height: 206px;
    overflow: hidden;
    color: white;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 14px;
    margin: 24px;
    border-radius: 4px;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    word-wrap: normal;

    @media (min-width: 750px) {
        &:hover {
            transform: scale(1.05);
            background-color: rgba(70, 25, 25, 0.9);
            box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 14px;
        }
    }
}

.card-illustration {
    border-radius: 12px;
    padding-top: 10px;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    &.loaded {
        display: flex;
    }
}

.card-title {
    font-weight: 600;
    font-size: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
}
