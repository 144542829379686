@import "../../utilities/styleConstants.scss";

.header-wrapper {
    .header-bar-box {
        width: 100vw;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        background: rgb(32, 21, 17);
        background: linear-gradient(108deg, rgba(15, 9, 6, 1) 0%, rgba(38, 21, 17, 1) 85%);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        position: fixed;
        top: 0;
        left: 0;
        height: $header-height-desktop;
        z-index: 999;
        will-change: transform;

        @media (max-width: 500px) {
            height: $header-height-mobile;
        }

        .page-info-bar {
            display: none;
            width: 100%;
            margin: 0 10px;
            padding: 3px 15px 0px 15px;
            height: 60%;
            min-height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-transform: uppercase;
            color: white;
            border-right: none;
            letter-spacing: 0;
            font-size: 20px;
            font-weight: bold;
            @media (min-width: 550px) {
                border-right: 2px solid rgb(85, 40, 35);
                display: flex;
            }

            .flag-icon-button {
                height: 16px;
                width: 22px;
                min-width: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                overflow: hidden;
                margin: 4px 10px 0 0;
                background: transparent;
                cursor: pointer;
                transition: 0.2s transform ease-in-out;
                border: 6px solid transparent;
                &.selected {
                    border: 6px solid rgb(117, 7, 7);
                }

                &:hover {
                    transform: scale(1.15);
                }
                @media (max-width: 550px) {
                    margin-right: 0;
                }
            }

            .selected-page-title {
                width: auto;
                margin-left: 18px;
                display: flex;
                align-items: center;
                padding-top: 4px;
                font-size: 18px;
                @media (max-width: 960px) {
                    margin-left: 16px;
                    font-size: 15px;
                    max-width: 120px;
                }
                @media (max-width: 320px) {
                    margin-left: 8px;
                    font-size: 13px;
                    max-width: 100px;
                }
            }
        }

        .title-text {
            text-transform: uppercase;
            margin-right: 15px;
            font-weight: bold;
            font-size: 20px;
            display: none;
            padding: 8px 8px 4px 8px;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                transform: scale(0.985);
            }

            @media (min-width: 550px) {
                display: block;
                min-width: 180px;
            }

            @media (min-width: 960px) {
                font-size: 30px;
                min-width: 260px;
            }
        }
    }
}
