.introduction {
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgb(248, 230, 230);
    font-size: 46px;
}

.content-wrapper {
    display: flex;
    justify-content: center;

    .karate-section-image-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
            width: 100%;
            max-width: 600px;
            border-radius: 16px;
            margin: 24px 0;
        }
    }

    .kyokushin-section-image-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        img {
            width: 100%;
            max-width: 150px;
            border-radius: 16px;
            margin: 0 16px 0 0;

            @media (max-width: 960px) {
                margin: 16px 0;
            }
        }

        @media (max-width: 960px) {
            flex-direction: column;
        }
    }
}
