.main-background {
    color: white;
    background: rgb(24, 8, 8);
    background-image: url("../../assets/japaneseTemple.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.main-content-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
    overflow: auto;
    overflow-x: hidden;
}

.main-background::-webkit-scrollbar {
    width: 12px;
}
