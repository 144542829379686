.calendars-outer-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .page-title {
        margin-top: 20px;
        color: rgb(161, 60, 60);
        font-size: 26px;

        @media (max-width: 500px) {
            margin-top: 0;
            font-size: 22px;
        }
    }

    .page-alternative-note {
        font-size: 14px;
        margin: 5px;
        color: rgb(127, 86, 67);
        @media (max-width: 500px) {
            font-size: 12px;
        }
        .link {
            color: rgb(148, 89, 50);
            text-decoration: none;
            font-weight: bold;
            &:hover {
                color: rgb(197, 118, 65);
            }
        }

        &:last-of-type {
            margin-top: 36px;
        }
    }

    .calendars-inner-box {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: center;
        width: 80%;
        margin-top: 24px;

        .buttons-panel {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .calendar-button {
                font-weight: bold;
                text-decoration: none;
                color: inherit;
                background: linear-gradient(90deg, rgb(73, 23, 19) 0%, rgb(85, 36, 26) 100%);
                margin: 26px 20px 8px 20px;
                padding: 16px 10px;
                width: 120px;
                border-radius: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                .calendar-name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 17px;
                    height: 35px;
                    width: 120px;
                    color: rgb(224, 210, 210);
                    margin-bottom: 15px;
                }

                &:hover {
                    transform: scale(1.03);
                    background: linear-gradient(90deg, rgb(82, 18, 15) 0%, rgb(85, 36, 26) 100%);
                }

                img {
                    padding: 8px 12px;
                    background-color: rgba(252, 133, 133, 0.13);
                    border-radius: 10px;
                    font-size: 18px;
                }
            }
            .alt-calendar-button {
                text-decoration: none;
                margin: 4px 0;
                padding: 4px;
                font-size: 11px;
                background: linear-gradient(90deg, rgb(224, 184, 184) 0%, rgb(175, 146, 146) 100%);
                color: rgb(48, 37, 37);
                font-weight: bold;
                border-radius: 15px;
                width: 100px;
                text-align: center;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    color: rgb(66, 26, 26);
                    width: 112px;
                }
            }
        }
    }
}
