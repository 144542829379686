.placeholder {
    height: 100%;
    margin: 0 auto;
    background-color: transparent;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -350px 0;
    }
    100% {
        background-position: 350px 0;
    }
}

.animated-background {
    height: 100%;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: transparent;
    background: linear-gradient(to right, rgba(211, 208, 208, 0.02) 2%, rgba(218, 217, 217, 0.09) 33%, transparent 50%);
    position: relative;
}
