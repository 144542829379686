body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  height: 100vh;
  font-family: 'Euclid Circular Bold';
  letter-spacing: -0.01em;
}

body,
#root {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'Euclid Circular';
  src: local('Euclid Circular Regular'), local('Euclid-Circular-Regular'),
    url('./fonts/EuclidCircularA-Regular.woff2') format('woff2'),
    url('./fonts/EuclidCircularA-Regular.woff') format('woff'),
    url('./fonts/EuclidCircularA-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular Bold';
  src: local('Euclid Circular Bold'), local('Euclid-Circular-Bold'),
    url('./fonts/EuclidCircularA-Bold.woff2') format('woff2'),
    url('./fonts/EuclidCircularA-Bold.woff') format('woff'),
    url('./fonts/EuclidCircularA-Bold.ttf') format('truetype');
  font-style: normal;
}

::-webkit-scrollbar {
  background-color: rgb(8, 4, 4);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(51, 17, 17);
  border-radius: 8px;
}
