.introduction {
    text-align: center;
}

.definition-section-image-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
        width: 100%;
        max-width: 460px;
        border-radius: 16px;
        margin: 0 0 36px 0;

        @media (max-width: 960px) {
            max-width: 280px;
            margin: 16px 0;
        }
    }
}
