.contacts-outer-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .contacts-section-map {
        padding-top: 15px;
        margin-top: 15px;
        position: relative;

        .contacts-map-img {
            max-width: 100%;
            border-radius: 12px;
            display: none;
            &.loaded {
                display: block;
            }
        }

        .contacts-map-pin {
            position: absolute;
            display: none;
            bottom: 36px;
            left: 76px;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            font-size: 12px;
            color: white;
            border-radius: 50%;
            background-color: rgb(126, 83, 83);
            height: 12px;
            width: 12px;
            padding: 8px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            border: 1px solid white;

            &.loaded {
                display: flex;
            }

            &:hover {
                box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.6), 0 0 14px 6px rgba(255, 255, 255, 0.28);
                transform: scale(1.06);
            }
        }

        @media (max-width: 360px) {
            padding-top: 0;
            margin-top: 0;
            transform: scale(0.7);
            margin-left: -54px;
            padding-left: 0;
        }
    }

    .contacts-section {
        padding: 15px;
        margin: 15px;
        .contacts-section-title {
            text-transform: uppercase;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.666);
            margin-bottom: 24px;
            @media (max-width: 960px) {
                margin-bottom: 8px;
                margin-top: 8px;
            }
        }

        .contacts-section-facebook-button {
            font-size: 20px;
            a {
                text-decoration: none;
                color: white;
                font-weight: bold;
                width: fit-content;
                min-width: 172px;
                padding: 8px;
                border-radius: 12px;
                border: 1px solid rgb(109, 32, 54);
                cursor: pointer;
                background-color: rgb(87, 23, 40);
                white-space: nowrap;
                img {
                    border-radius: unset;
                    margin-right: 8px;
                }
                display: flex;
                align-items: center;

                &:hover {
                    background-color: rgb(128, 30, 56);
                    text-decoration: underline;
                }
            }
        }

        .contacts-section-text {
            font-size: 22px;
            padding-top: 6px;
            a {
                text-decoration: none;
                color: white;
                display: flex;
                align-items: center;
                margin-top: -2px;
                img {
                    margin-right: 8px;
                    padding-top: 4px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            .contacts-with-icon {
                display: flex;
                align-items: center;
                margin-top: 4px;
                img {
                    margin-right: 8px;
                }
            }
            section {
                margin-bottom: 4px;
            }
        }

        @media (max-width: 960px) {
            padding: 8px 0;
            margin: 8px 0;
        }
    }
    @media (max-width: 960px) {
        flex-direction: column;
    }
}
